import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import ProjectCard from "../../components/projects/ProjectCard"
import Image from "gatsby-image"
import { Helmet } from "react-helmet"
import Seo from "../../components/seo"

const Project = ({ data }) => {
  const projects = data?.project?.nodes?.map((node, index) => {
    return {
      title: node.frontmatter.title,
      techs: node.frontmatter.technologies.split(","),
      slug: node.frontmatter.slug,
      desc: node.frontmatter.desc,
      url: node.frontmatter.url,
      fluid: node?.frontmatter?.thumbnail?.childImageSharp?.fluid,
      category: node.frontmatter.category,
    }
  })
  console.log(projects)

  const filters = {
    DETAILED: "Details",
    MOCKUPS: "Mockups",
    YOUTUBE: "Youtube Videos",
    VIMEO: "Vimeo Videos",
  }

  return (
    <Layout>
      <Seo title="Projects"></Seo>
      {/* <Helmet>
        <title> Projects</title>
      </Helmet> */}

      <div className="p-20 sm:p-8">
        <h1 className="text-[#151717] dark:text-[#BDEBEA] font-semibold text-2xl my-4">
          Projects
        </h1>
        <Image fluid={data?.image?.childImageSharp?.fluid} />
        <div className="grid grid-cols-2 sm:grid-cols-1 gap-4">
          {projects.length > 0 &&
            projects.map((project, index) => (
              <ProjectCard {...project} key={index} />
            ))}
        </div>
      </div>
    </Layout>
  )
}

export default Project

export const query = graphql`
  query ProjectsPage {
    project: allMarkdownRemark {
      nodes {
        frontmatter {
          title
          slug
          technologies
          desc
          url
          category
          thumbnail {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        id
      }
    }
  }
`

// query MyQuery {
//   allFile(
//     filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "thumbnail"}, name: {eq: "GeetWedding"}}
//   ) {
//     nodes {
//       name
//       relativePath
//       childImageSharp {
//         fluid {
//           src
//           srcSet
//         }
//       }
//     }
//   }
// }
